import React from 'react';

import '../../../../assets/styles/countrylife.css';
import { loadCSS } from '../../../../utils/loadCSS';

loadCSS('countrylife-site');

export const CountrylifeSiteWrapper: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return <>{children}</>;
};
export default CountrylifeSiteWrapper;
